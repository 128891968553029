import { DeveloperInfo } from '@/Components/Home/HotDevelopers';
import CheckCrossIconSvg from '@/Components/SVG/CheckCrossIconSvg';
import SlideRightSvg from '@/Components/SVG/SlideRightSvg';
import styles from '@/styles/Cards/Developer/hotdeveloper.module.scss';
import { NumberFormat } from '@/utils';
import { ImageToBase64, LoaderShimmer } from '@/utils/imageHelpers';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
interface developerData {
  data: DeveloperInfo;
}
const HotDeveloperCard = ({ data }: developerData) => {
  const router = useRouter();
  const [value, setValue] = useState({
    txn: false,
    sales: true,
  });
  const [percentTxnChange, setpercentTxnChange] = useState<null | string>(null);
  const [percentVolumeChange, setpercentVolumeChange] = useState<null | string>(
    null
  );
  const [percentTxnSecondaryChange, setpercentTxnSecondaryChange] = useState<
    null | string
  >(null);
  const [percentVolumeSecondaryChange, setpercentVolumeSecondaryChange] =
    useState<null | string>(null);
  const [percentTxnPrimaryChange, setpercentTxnPrimaryChange] = useState<
    null | string
  >(null);
  const [percentVolumePrimaryChange, setpercentVolumePrimaryChange] = useState<
    null | string
  >(null);

  // useEffect(() => {
  //   if (data) {
  //     setpercentTxnChange(
  //       CalculatePercentChange(
  //         data.prevYearTransactions,
  //         data.totalTransactions
  //       )
  //     );
  //     setpercentVolumeChange(
  //       CalculatePercentChange(data.previousTotalSales, data.totalSales)
  //     );
  //     setpercentTxnSecondaryChange(
  //       CalculatePercentChange(
  //         data.prevSecondaryTransactions,
  //         data.totalSecondaryTransactions
  //       )
  //     );
  //     setpercentVolumeSecondaryChange(
  //       CalculatePercentChange(
  //         data.prevSecondaryTotalSales,
  //         data.totalSecondarySales
  //       )
  //     );
  //     setpercentTxnPrimaryChange(
  //       CalculatePercentChange(
  //         data.prevPrimaryTransactions,
  //         data.totalPrimaryTransactions
  //       )
  //     );
  //     setpercentVolumePrimaryChange(
  //       CalculatePercentChange(
  //         data.prevPrimaryTotalSales,
  //         data.totalPrimarySales
  //       )
  //     );
  //   }

  //   return () => {
  //     setpercentTxnChange(null);
  //     setpercentVolumeChange(null);
  //   };
  // }, [data]);

  return (
    <div className={styles.cardContainer}>
      <header className={styles.cardContainer_head}>
        <Image
          src={data.overviewdata?.developerImage}
          placeholder={`data:image/svg+xml;base64,${ImageToBase64(
            LoaderShimmer(700, 475)
          )}`}
          height={100}
          width={100}
          alt={data._id}
        />
        <aside>
          <span>#{data.rankdata?.rank}</span>
        </aside>
      </header>
      <section></section>
      <main className={styles.cardContainer_main}>
        <h3>{data._id}</h3>
        <span>
          <CheckCrossIconSvg /> {NumberFormat(data.projects)} Projects
        </span>
      </main>
      <section></section>
      {/* <main className={styles.cardContainer_checks}>
        <FormGroup className={styles.cardContainer_checks_form}>
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<CheckCircleIcon />}
                icon={<RadioButtonUncheckedIcon />}
                checked={value.sales}
                onChange={(e) => {
                  setValue({
                    txn: !e.target.checked,
                    sales: e.target.checked,
                  });
                }}
                sx={{
                  color: '#d12d34',
                  '&.Mui-checked': {
                    color: '#d12d34',
                  },
                }}
              />
            }
            label="Sales Value"
          />
          <FormControlLabel
            control={
              <Checkbox
                checkedIcon={<CheckCircleIcon />}
                icon={<RadioButtonUncheckedIcon />}
                checked={value.txn}
                onChange={(e) => {
                  setValue({
                    sales: !e.target.checked,
                    txn: e.target.checked,
                  });
                }}
                sx={{
                  color: '#6AC4BF',
                  '&.Mui-checked': {
                    color: '#6AC4BF',
                  },
                }}
              />
            }
            label="Sales Volume"
          />
        </FormGroup>
        <div className={styles.cardContainer_checks_data}>
          <p>Sales {value.sales ? 'Value' : 'Volume'} 2023 </p>
          {value.sales ? (
            <span>
              {convertNumberToPrice(data.totalSales).replace('AED', '')}{' '}
              {percentVolumeChange?.includes('+') ||
              percentVolumeChange === '-0%' ? (
                <span style={{ color: '#36744f' }}>
                  <div>
                    <ArrowUpwardIcon
                      sx={{ color: '#36744F', height: '18px' }}
                    />
                  </div>
                  {percentVolumeChange === '-0%' ? '0%' : percentVolumeChange}
                </span>
              ) : (
                <span style={{ color: '#d12d34' }}>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <ArrowUpwardIcon
                      sx={{ color: '#d12d34', height: '18px' }}
                    />
                  </div>
                  {percentVolumeChange === '-0%' ? '0%' : percentVolumeChange}
                </span>
              )}
              vs 2022
            </span>
          ) : (
            <span>
              {convertNumberToPrice(data.totalTransactions).replace('AED', '')}{' '}
              {percentTxnChange?.includes('+') || percentTxnChange === '-0%' ? (
                <span style={{ color: '#36744f' }}>
                  <div>
                    <ArrowUpwardIcon
                      sx={{ color: '#36744F', height: '18px' }}
                    />
                  </div>
                  {percentTxnChange === '-0%' ? '0%' : percentTxnChange}
                </span>
              ) : (
                <span style={{ color: '#d12d34' }}>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <ArrowUpwardIcon
                      sx={{ color: '#d12d34', height: '18px' }}
                    />
                  </div>
                  {percentTxnChange === '-0%' ? '0%' : percentTxnChange}
                </span>
              )}
              vs 2022
            </span>
          )}
        </div>
      </main>
      <section></section> */}
      {/* <main className={styles.cardContainer_mains}>
        <div className={styles.cardContainer_mains_left}>
          <p>Primary {value.sales ? 'Sales' : 'Volume'}</p>
          {value.sales ? (
            <span>
              {convertNumberToPrice(data.totalPrimarySales).replace('AED', '')}{' '}
              {percentVolumePrimaryChange?.includes('+') ||
              percentVolumePrimaryChange === '-0%' ? (
                <span style={{ color: '#36744f' }}>
                  <div>
                    <ArrowUpwardIcon
                      sx={{ color: '#36744F', height: '18px' }}
                    />
                  </div>
                  {percentVolumePrimaryChange === '-0%'
                    ? '0%'
                    : percentVolumePrimaryChange}
                </span>
              ) : (
                <span style={{ color: '#d12d34' }}>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <ArrowUpwardIcon
                      sx={{ color: '#d12d34', height: '18px' }}
                    />
                  </div>
                  {percentVolumePrimaryChange === '-0%'
                    ? '0%'
                    : percentVolumePrimaryChange}
                </span>
              )}
              vs 2022
            </span>
          ) : (
            <span>
              {convertNumberToPrice(data.totalPrimaryTransactions).replace(
                'AED',
                ''
              )}{' '}
              {percentTxnPrimaryChange?.includes('+') ||
              percentTxnPrimaryChange === '-0%' ? (
                <span style={{ color: '#36744f' }}>
                  <div>
                    <ArrowUpwardIcon
                      sx={{ color: '#36744F', height: '18px' }}
                    />
                  </div>
                  {percentTxnPrimaryChange === '-0%'
                    ? '0%'
                    : percentTxnPrimaryChange}
                </span>
              ) : (
                <span style={{ color: '#d12d34' }}>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <ArrowUpwardIcon
                      sx={{ color: '#d12d34', height: '18px' }}
                    />
                  </div>
                  {percentTxnPrimaryChange === '-0%'
                    ? '0%'
                    : percentTxnPrimaryChange}
                </span>
              )}
              vs 2022
            </span>
          )}
        </div>
        <aside></aside>
        <div className={styles.cardContainer_mains_right}>
          <p>Secondary {value.sales ? 'Sales' : 'Volume'}</p>
          {value.sales ? (
            <span>
              {convertNumberToPrice(data.totalSecondarySales).replace(
                'AED',
                ''
              )}{' '}
              {percentVolumeSecondaryChange?.includes('+') ||
              percentVolumeSecondaryChange === '-0%' ? (
                <span style={{ color: '#36744f' }}>
                  <div>
                    <ArrowUpwardIcon
                      sx={{ color: '#36744F', height: '18px' }}
                    />
                  </div>
                  {percentVolumeSecondaryChange === '-0%'
                    ? '0%'
                    : percentVolumeSecondaryChange}
                </span>
              ) : (
                <span style={{ color: '#d12d34' }}>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <ArrowUpwardIcon
                      sx={{ color: '#d12d34', height: '18px' }}
                    />
                  </div>
                  {percentVolumeSecondaryChange === '-0%'
                    ? '0%'
                    : percentVolumeSecondaryChange}
                </span>
              )}
              vs 2022
            </span>
          ) : (
            <span>
              {convertNumberToPrice(data.totalSecondaryTransactions).replace(
                'AED',
                ''
              )}{' '}
              {percentTxnSecondaryChange?.includes('+') ||
              percentTxnSecondaryChange === '-0%' ? (
                <span style={{ color: '#36744f' }}>
                  <div>
                    <ArrowUpwardIcon
                      sx={{ color: '#36744F', height: '18px' }}
                    />
                  </div>
                  {percentTxnSecondaryChange === '-0%'
                    ? '0%'
                    : percentTxnSecondaryChange}
                </span>
              ) : (
                <span style={{ color: '#d12d34' }}>
                  <div style={{ transform: 'rotate(180deg)' }}>
                    <ArrowUpwardIcon
                      sx={{ color: '#d12d34', height: '18px' }}
                    />
                  </div>
                  {percentTxnSecondaryChange === '-0%'
                    ? '0%'
                    : percentTxnSecondaryChange}
                </span>
              )}
              vs 2022
            </span>
          )}
        </div>
      </main> */}

      <footer
        className={styles.cardContainer_footer}
        onClick={() => router.push(`projects?developer=${data._id}`)}
      >
        <span>
          View all Projects by {data._id} ({data.rankdata?.totalProjects})
        </span>
        <div>
          <SlideRightSvg color2="#d12d34" color="#fff" width={26} height={26} />{' '}
        </div>
      </footer>
    </div>
  );
};

export default HotDeveloperCard;
